<template>
  <Layout class="OrderManagement">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="goodsId">
          <el-input
            v-model="formData.goodsId"
            clearable
            placeholder="商品ID"
            @change="formData.goodsId = formData.goodsId.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item prop="orderId">
          <el-input
            v-model="formData.orderIdStr"
            clearable
            placeholder="订单号"
            @change="formData.orderIdStr = formData.orderIdStr.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item prop="userId">
          <el-input
            v-model="formData.userIdStr"
            clearable
            placeholder="用户UID"
            @change="formData.userIdStr = formData.userIdStr.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>

    <div
      v-loading="listInfo.loading"
      class="table"
    >
      <div class="header">
        <div class="goods-info col-1">
          商品信息
        </div>
        <div class="price col-2">
          用户名/ID
        </div>
        <div class="userName col-3">
          收货信息
        </div>
        <div class="ReceivingGoods col-4">
          商品标价(美元)
        </div>
        <div class="freight col-5">
          运费
        </div>
        <div class="AmountActuallyReceived col-6">
          实收金额
        </div>
        <div class="orderStatus col-7">
          订单创建时间
        </div>
        <div class="orderStatus col-8">
          订单状态
        </div>
      </div>
      <div class="body">
        <template v-if="listInfo.list.length">
          <TableRow
            v-for="(item, index) in listInfo.list"
            :key="index"
            :row="item"
          />
        </template>
        <template v-else>
          <div class="empty">
            暂无数据
          </div>
        </template>
      </div>
    </div>

    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  getMallv2PhysicalGoodsOrderList
} from '@/api/electronicEntity.js'
import TableRow from '@/views/electronic-entity/components/TableRow'

export default {
  components: { TableRow },
  data () {
    return {
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      }
    }
  },
  created () {
    this.handleSearch()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      const formData = this.formData
      this.listInfo.loading = true
      getMallv2PhysicalGoodsOrderList(formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    }
  }
}
</script>

<style lang="less">
  .OrderManagement{
    .layout-content{
      .col-1{flex: 200; min-width: 200px;}
      .col-2{flex: 200; min-width: 200px; }
      .col-3{flex: 200; min-width: 200px; }
      .col-4{flex: 100; min-width: unset; }
      .col-5{flex: 100; min-width: unset;}
      .col-6{flex: 100; min-width: unset;}
      .col-7{flex: 120; min-width: 200px; text-align: center;}
      .col-8{flex: 120; min-width: unset; }
    }
  }
</style>
<style scoped lang="less">
  .table{
    overflow-x: auto;
    .header{
      display: flex;
      border-bottom: 1px solid #EBEEF5;
      padding: 0 20px;
      >div{
        color: #909399;
        font-weight: bolder;
        font-size: 14px;
        line-height: 40px;
        &.orderStatus{
          text-align: center;
        }
      }
    }
    .body{
      .empty{
        text-align: center;
        font-size: 14px;
        color: #909399;
        line-height: 60px;
        border-bottom: 1px solid #EBEEF5;
      }
    }
  }
</style>
