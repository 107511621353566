<template>
  <div class="TableRow">
    <div class="row-header">
      <div class="logisticsInfo">
        <template v-if="isDelivered">
          <div class="item">
            快递公司: {{ row.courierCompany || '/' }}
          </div>
          <div class="item">
            订单号: {{ row.shipmentNum || '/' }}
          </div>
        </template>
      </div>
      <div class="edit">
        <i
          v-if="isDelivered"
          class="el-icon-edit"
          @click="doUpdateLogisticsInfo(true)"
        />
      </div>
    </div>
    <div class="row-body">
      <div class="block-info col-1">
        <el-image
          class="goods-cover"
          fit="contain"
          :src="row.headerImage"
          lazy
        />
        <span>{{ row.goodsName }}</span>
      </div>
      <div class="col-2">
        <div class="username">
          UserName: {{ row.userName }}
        </div>
        <div class="uid">
          UID: {{ row.userIdStr }}
        </div>
      </div>
      <div class="col-3">
        <div>{{ address.firstName + ' ' + address.lastName }}</div>
        <div>{{ address.nation + ' , ' + address.region + ' ' }}{{ address.post }}</div>
        <div>{{ address.address }}</div>
        <div>{{ address.phone }}</div>
      </div>
      <div class="col-4">
        {{ row.goodsPrice | priceFilter }}
      </div>
      <div class="col-5">
        {{ row.freightPrice | priceFilter }}
      </div>
      <div class="col-6">
        {{ row.actualPayment | priceFilter }}
      </div>
      <div class="col-7">
        {{ row.createTime }}
      </div>
      <div class="col-8 ctrl-area">
        <div>{{ (entityOrderStatusMap[row.orderStatus] || {}).name || '未知' }}</div>
        <el-select
          v-model="tempOrderStatus"
          v-loading="updateLoading"
          :loading="updateLoading"
          clearable
          placeholder="变更状态"
          style="margin-top: 10px;"
          @change="doOrderStatusChange"
        >
          <el-option
            v-for="item in entityOrderStatusList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="editDialogVisible"
      width="500px"
    >
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item
          label="快递公司"
          prop="courierCompany"
        >
          <el-input v-model="formData.courierCompany" />
        </el-form-item>
        <el-form-item
          label="物流订单号"
          prop="shipmentNum"
        >
          <el-input v-model="formData.shipmentNum" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="formLoading"
          @click="updateLogisticsInfo"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { entityOrderStatusMap, entityOrderStatusList } from '@/utils/selectOptions'
import {
  updateMallv2PhysicalOrderLogisticsInfo,
  updateMallv2PhysicalOrderStatus
} from '@/api/electronicEntity.js'
export default {
  name: 'TableRow',
  filters: {
    priceFilter (price) {
      if (!price) return '/'
      return '$' + price.toFixed(2)
    }
  },
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      entityOrderStatusMap,
      entityOrderStatusList,
      tempOrderStatus: '',
      updateLoading: false,
      editDialogVisible: false,
      dialogTitle: '添加物流信息',
      formData: {},
      rules: {
        courierCompany: [
          { required: true, message: '快递公司必填' }
        ],
        shipmentNum: [
          { required: true, message: '物流订单号必填' }
        ]
      },
      formLoading: false
    }
  },
  computed: {
    isDelivered () {
      return this.row.orderStatus === 300
    },
    address () {
      return this.row.address || {}
    }
  },
  methods: {
    doOrderStatusChange () {
      if (!this.tempOrderStatus || this.tempOrderStatus === this.row.orderStatus) {
        this.tempOrderStatus = ''
        return
      }
      if (this.tempOrderStatus === 300) {
        this.tempOrderStatus = ''
        this.doUpdateLogisticsInfo()
      } else {
        this.updateOrderStatus(this.tempOrderStatus)
      }
    },
    updateOrderStatus (status) {
      this.updateLoading = true
      updateMallv2PhysicalOrderStatus({
        id: this.row.id,
        orderStatus: status
      }).then(res => {
        if (res.code === 200) {
          this.tempOrderStatus = ''
          this.$set(this.row, 'orderStatus', status)
        }
      })
        .finally(() => {
          this.updateLoading = false
        })
    },
    doUpdateLogisticsInfo (isEdit) {
      this.dialogTitle = isEdit ? '更新物流信息' : '添加物流信息'
      this.editDialogVisible = true
      const { courierCompany, shipmentNum } = this.row
      this.formData = {
        courierCompany,
        shipmentNum
      }
    },
    updateLogisticsInfo () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.formLoading = true
          updateMallv2PhysicalOrderLogisticsInfo({
            id: this.row.id,
            ...this.formData
          })
            .then((res) => {
              if (res.code === 200) {
                Object.assign(this.row, this.formData)
                this.editDialogVisible = false
                this.updateOrderStatus(300)
              }
            })
            .finally(() => {
              this.formLoading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .TableRow{
    border: 1px solid #EBEEF5;
    margin-top: 10px;
    .row-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f2f2f4;
      font-size: 14px;
      padding: 0 20px;
      height: 35px;
      .logisticsInfo {
        display: flex;
      }
      .edit {
        i {
          cursor: pointer;
        }
      }
      .item{
        margin-right: 20px;
        line-height: 40px;
      }
    }
    .row-body{
      display: flex;
      padding: 20px;
      font-size: 14px;
      line-height: 22px;
      .block-info{
        flex-direction: row;
        align-items: center;
        justify-content: normal;
        .goods-cover{
          width: 80px;
          height: 80px;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
      >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.ctrl-area{
          align-items: center;
        }
      }
    }
  }
</style>
